import { Badge, Box, Table } from "@mantine/core";
import React, { useState } from "react";
import { getDateRange } from "../../../utils/leaves/getDateRange";

import { getStatusColor } from "./helper";
import ActionBtn from "./ActionBtn";

interface CustomTableProps {
  data: TLeave[];
  itemPerPage: number;
  type?: "all" | "pending";
}

export const CustomTable: React.FC<CustomTableProps> = ({
  data,
  itemPerPage,
  type,
}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const handleRowClick = (id: string) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const emptyRows = itemPerPage - data.length;

  return (
    <Box style={{ overflow: "auto" }}>
      <Table
        verticalSpacing="md"
        highlightOnHover
        sx={{
          border: "2px solid #ddd",
          borderRadius: "8px",
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Student Name</th>
            <th style={{ textAlign: "center" }}>Email</th>
            <th style={{ textAlign: "center" }}>Category</th>
            <th style={{ textAlign: "center" }}>From</th>
            <th style={{ textAlign: "center" }}>To</th>
            <th style={{ textAlign: "center" }}>Type</th>
            <th style={{ width: "200px", textAlign: "center" }}>Reason</th>
            <th style={{ textAlign: "center" }}>Status</th>
            {type && <th style={{ textAlign: "center" }}>Action</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((leave) => {
            const { from, to } = getDateRange(leave.dates);
            const isExpanded = expandedRow === leave._id;

            return (
              <tr
                key={leave._id}
                style={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  height: isExpanded ? "auto" : "50px",
                }}
                onClick={() => handleRowClick(leave._id)}
              >
                <td style={{ textAlign: "center" }}>{leave.studentId.name}</td>
                <td style={{ textAlign: "center" }}>{leave.studentId.email}</td>
                <td
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  {leave.categoryId?.name ?? "Deleted Category"}
                </td>
                <td style={{ textAlign: "center" }}>{from}</td>
                <td style={{ textAlign: "center" }}>{to}</td>
                <td
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  {leave.type}
                </td>
                <td
                  style={{
                    maxWidth: "250px",
                    minWidth: "250px",
                    whiteSpace: isExpanded ? "normal" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign:
                      isExpanded && leave.reason.length > 30
                        ? "left"
                        : "center",
                  }}
                >
                  {leave.reason}
                </td>
                <td
                  style={{
                    textTransform: "capitalize",
                    alignItems: "center",
                  }}
                >
                  <Badge color={getStatusColor(leave.status)} variant="filled">
                    {leave.status}
                  </Badge>
                </td>
                {type && (
                  <td align="center">
                    <ActionBtn
                      leaveId={leave._id}
                      disabled={leave.status !== "pending"}
                    />
                  </td>
                )}
              </tr>
            );
          })}

          {emptyRows > 0 &&
            Array.from({ length: emptyRows }).map((_, index) => (
              <tr key={`empty-${index + 1}`} style={{ height: "56px" }}>
                <td
                  colSpan={8}
                  style={{ textAlign: "center", color: "#aaa" }}
                ></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Box>
  );
};
