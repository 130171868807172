import React, { memo } from "react";
import { Box, Textarea as Input, Text, TextareaProps } from "@mantine/core";
import { GetInputProps } from "@mantine/form/lib/types";

interface IInputField {
  label: string;
  getInputProps: GetInputProps<TextareaProps>;
  name: string;
}

const Textarea: React.FC<IInputField> = ({ getInputProps, label, name }) => {
  return (
    <Box>
      <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
        {label}
      </Text>
      <Input placeholder="" {...getInputProps(name)} />
    </Box>
  );
};

export default memo(Textarea);
